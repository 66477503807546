<template>
  <div class="search-villages-results-container search-results-container">
    <base-pagination-meta :meta="meta"></base-pagination-meta>
    <div class="search-results">
      <search-villages-result-item v-for="item in results" :key="item.id" :item="item"></search-villages-result-item>
    </div>
    <base-pagination v-if="showPagination" :meta="meta" @onSwitchPage="onSwitchPage"></base-pagination>
  </div>
</template>

<script>
import BasePagination from '@common/elements/BasePagination.vue';
import BasePaginationMeta from '@common/elements/BasePaginationMeta.vue';
import SearchVillagesResultItem from '@common/pages/searchVillages/SearchVillagesResultItem';

export default {
  props: {
    meta: Object,
    results: Array,
  },
  computed: {
    showPagination() {
      return this.meta.next || this.meta.previous;
    },
  },
  methods: {
    onSwitchPage(page) {
      this.$emit('switch-page', page);
    },
  },
  components: {SearchVillagesResultItem, BasePagination, BasePaginationMeta},
};
</script>

<style scoped></style>
