<template>
  <div class="search-villages-page">
    <div class="limited_content">
      <h4>Search Chinese Villages</h4>
      <div class="search-content-wrapper">
        <div class="search-controls">
          <search-villages-form-container :errors="errors" @submit="onSubmit"></search-villages-form-container>
          <mcr-button class="search-button" :loading="placeSearchLoadingState" @click="onSubmit">{{
            searchButtonLabel
          }}</mcr-button>
        </div>
        <div class="search-content search-results-content">
          <mcr-loading-indicator v-if="placeSearchLoadingState" :loading="true"></mcr-loading-indicator>
          <search-error v-else-if="searchError" :text="searchErrorText"></search-error>
          <template v-else-if="showResults">
            <search-villages-results
              v-if="placeSearchState.length"
              :meta="placeSearchMetaState"
              :results="placeSearchState"
              @switch-page="onSwitchPage"
            ></search-villages-results>
            <search-villages-results-empty v-else></search-villages-results-empty>
          </template>

          <div class="search-results-container search-tips">
            <div class="getting-started" v-if="!showResults && !searchError">
              <div class="bold">Find your Ancestral Village.</div>
              <p>
                Trace the
                <router-link class="bold" :to="{name: 'wiki-article', params: {slug: 'ancestral-home'}}"
                  >ancestral place</router-link
                >
                of your ancestors to unlock a treasure trove of family records from China.
              </p>
            </div>
            <div class="faq">
              <div class="bold">Frequently Asked Questions</div>
              <ul class="desc">
                <li>
                  <mcr-wiki-glossary-link
                    naturalId="whySearchVillage"
                    text="Why should I search for my ancestral village?"
                  />
                </li>
                <li>
                  <mcr-wiki-glossary-link naturalId="howSearchVillage" text="How do I find my village in China?" />
                </li>
                <li>
                  <mcr-wiki-glossary-link naturalId="knowVillage" text="I know the name of my village. Now what?" />
                </li>
              </ul>
            </div>
            <div class="village-location">
              Want help from the experts? Try our
              <router-link :to="{name: 'village-location-service'}" class="bold">Village Location Service</router-link>
            </div>
            <div class="search-link">
              Looking for places with Chinese communities outside China? Try our
              <router-link :to="{name: 'search-overseas'}" class="bold"
                >Overseas Chinese Communities Search</router-link
              >
            </div>

            <stand-out-block-split class="section" v-if="showResults && !placeSearchState.length">
              <div slot="message">
                <h5>Need help finding your village?</h5>
              </div>
              <div slot="content">
                <mcr-button-router-link
                  :to="{name: 'village-location-service', query: {from: $route.fullPath}}"
                  @click="clickGetHelp"
                  :label="getHelpLabel"
                ></mcr-button-router-link>
              </div>
            </stand-out-block-split>
            <div v-else class="search-tool-intro">
              <cta-block-compare-plans></cta-block-compare-plans>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import SearchVillagesFormContainer from '@common/pages/searchVillages/SearchVillagesFormContainer';
import SearchVillagesResults from '@common/pages/searchVillages/SearchVillagesResults';
import SearchVillagesResultsEmpty from '@common/pages/searchVillages/SearchVillagesResultsEmpty';
import SearchError from '@common/pages/searches/results/SearchError';
import AnalyticsFacebookPixelHandler from '@common/utils/analytics/analytics.facebook.pixel';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import consts from '@common/utils/consts';
import {getPlaceSearchQueryByLevel, PLACE_SEARCH_QUERY_LEVELS} from '@common/utils/utils.place';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import {mapGetters} from 'vuex';

import StandOutBlockSplit from '@/base/elements/layouts/StandOutBlockSplit.vue';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  metaInfo: {
    title: 'Search Villages in China',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Find out where your ancestors came from and browse our growing database of 20,000+ current and historic places in Fujian and Guangdong.',
      },
    ],
  },
  mounted() {
    if (this.placeSearchState.length && this.isQueryParamsApplied) {
      this.scrollToSearchResults();
    }
    this.init();
  },
  data() {
    const filtersApplied =
      this.$route.query.q ||
      this.$route.query.place ||
      this.$route.query.surname ||
      this.$route.query.surname_name ||
      this.$route.query.level ||
      this.$route.query.migration_place_id;
    return {
      showResults: filtersApplied || this.$store.getters.placeSearchState.length,
      errors: {},
      searchError: false,
      searchErrorText: '',
      getHelpLabel: 'Yes, get help!',
    };
  },
  computed: {
    ...mapGetters([
      'placeSearchState',
      'placeSearchMetaState',
      'placeSearchLoadingState',
      'placeSearchQueryState',
      'placeSearchRootPlaceState',
      'placeSearchClanState',
      'placeSearchLevelState',
      'placeSearchMigrationPlaceState',
    ]),
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.tablet;
    },
    scrollToElement() {
      return this.isDesktop ? '.search-villages-page' : '.search-results-content';
    },
    scrollOffset() {
      return this.isDesktop ? -consts.MAIN_MENU_HEIGHT : -consts.MAIN_MENU_HEIGHT_MOBILE;
    },
    searchButtonLabel() {
      return this.showResults ? 'Update Search' : 'Search';
    },
    isQueryParamsApplied() {
      return (
        this.$route.query.q == this.placeSearchQueryState &&
        this.$route.query.place == this.placeSearchRootPlaceState.id && // query.place is str, place.id is int
        this.$route.query.migration_place_id == this.placeSearchMigrationPlaceState.id && // query.place is str, place.id is int
        (this.$route.query.surname == this.placeSearchClanState.object_id ||
          this.$route.query.surname_name == this.placeSearchClanState.name_ch ||
          this.$route.query.surname_name == this.placeSearchClanState.name_hant) &&
        PLACE_SEARCH_QUERY_LEVELS[this.$route.query.level] == this.placeSearchLevelState &&
        this.queryParamsPageIsDisplayed
      );
    },
    queryParamsPageIsDisplayed() {
      const pageNumber = parseInt(this.$route.query.page || 1);
      if (!this.placeSearchMetaState.limit) {
        return pageNumber === 1;
      }
      return pageNumber === this.placeSearchMetaState.offset / this.placeSearchMetaState.limit + 1;
    },
  },
  methods: {
    init() {
      const searchAllowed =
        this.$route.query.q ||
        this.$route.query.place ||
        this.$route.query.surname ||
        this.$route.query.surname_name ||
        this.$route.query.level ||
        this.$route.query.migration_place_id;
      if (!this.isQueryParamsApplied) {
        this.parseFiltersFromQuery();
        if (searchAllowed) {
          this.searchPlaces(this.$route.query.page);
        }
      }

      if (!searchAllowed) {
        this.showResults = false;
        this.$store.commit('setPlaceSearchState', []);
        this.$store.commit('setPlaceSearchMetaState', {});
      }
    },
    onSubmit() {
      this.errors = this.validateForm();
      if (!isEmpty(this.errors)) {
        return;
      }
      let filters = {
        q: this.placeSearchQueryState,
        place: this.placeSearchRootPlaceState.id,
        migration_place_id: this.placeSearchMigrationPlaceState.id,
        surname: this.placeSearchClanState.object_id,
        surname_name: this.placeSearchClanState.name_hant || this.placeSearchClanState.name_ch,
        level:
          this.placeSearchLevelState || this.placeSearchLevelState === 0
            ? getPlaceSearchQueryByLevel(this.placeSearchLevelState)
            : '',
      };
      filters = this.$route.query.page ? {...filters, page: 1} : filters;
      filters = pickBy(filters, value => !!value);
      if (isEmpty(filters)) {
        this.errors = {non_field: 'At least one filter is required'};
        return;
      }
      this.$router.push({query: filters}).catch(() => {});
      this.searchPlaces();
    },
    validateForm() {},
    searchPlaces(page) {
      this.searchError = false;
      this.searchErrorText = '';
      this.scrollToSearchResults();
      const params = {
        page: page || 1,
        q: this.placeSearchQueryState,
        root: this.placeSearchRootPlaceState.id,
        migration_place_id: this.placeSearchMigrationPlaceState.id,
        clan: this.placeSearchClanState.object_id,
        clan_name: this.placeSearchClanState.name_hant || this.placeSearchClanState.name_ch,
        level: this.placeSearchLevelState,
      };
      const filledParams = Object.keys(params).filter(key => params[key] && key !== 'page');
      const autoRedirect = filledParams.length === 1 && filledParams[0] === 'q';
      return this.$store
        .dispatch('searchAncestralPlacesAction', params)
        .then(res => {
          AnalyticsFacebookPixelHandler.trackSearchEvent({contentCategory: 'Villages'});
          AnalyticsMainHandler.trackVillageSearchSubmit(
            {
              q: params.q,
              root_place_id: params.root,
              clan_name: params.clan_name,
              migration_place_id: params.migration_place_id,
              level: params.level,
            },
            this.placeSearchState.length
          );
          if (autoRedirect && res.objects.length === 1) {
            this.$router.replace(getPlaceDetailRoute(res.objects[0].id, res.objects[0].pinyin));
          }
          this.showResults = true;
        })
        .catch(error => {
          this.searchError = true;
          this.searchErrorText = error && error.response && error.response.data ? error.response.data.error : '';
        });
    },
    parseFiltersFromQuery() {
      this.$store.commit('setPlaceSearchQueryState', this.$route.query.q || '');
      this.$store.commit('setPlaceSearchRootPlaceState', this.$route.query.place ? {id: this.$route.query.place} : {});
      this.$store.commit(
        'setPlaceSearchMigrationPlaceState',
        this.$route.query.migration_place_id ? {id: this.$route.query.migration_place_id} : {}
      );
      this.$store.commit(
        'setPlaceSearchClanState',
        this.$route.query.surname || this.$route.query.surname_name
          ? {object_id: this.$route.query.surname, name_ch: this.$route.query.surname_name}
          : {}
      );
      this.$store.commit(
        'setPlaceSearchLevelState',
        this.$route.query.level ? PLACE_SEARCH_QUERY_LEVELS[this.$route.query.level] : null
      );

      if (this.$route.query.place) {
        this.network.place.getPlacesDetail({id: this.$route.query.place, fields: 'full_address_en,id'}).then(res => {
          this.$store.commit('setPlaceSearchRootPlaceState', res);
        });
      }
      if (this.$route.query.surname && !this.$route.query.surname_name) {
        this.network.clan.get({ID: this.$route.query.surname}).then(res => {
          const {name_ch, name_hant, object_id, pinyin} = res;
          this.$store.commit('setPlaceSearchClanState', {name_ch, name_hant, object_id, pinyin});
          if (!this.userIsLoggedInState) {
            this.$store.commit('setSurnamesRegisterState', [name_hant || name_ch]);
          }
        });
      }
    },
    scrollToSearchResults() {
      this.$scrollTo(this.scrollToElement, {offset: this.scrollOffset, cancelable: false});
    },
    onSwitchPage(page) {
      this.saveToRouteQuery({page});
      this.searchPlaces(page);
    },
    saveToRouteQuery(filters) {
      let query = {...this.$route.query, ...filters};
      this.$router.push({query: query}).catch(() => {});
    },
    clickGetHelp() {
      AnalyticsMainHandler.trackClickContactButton(this.getHelpLabel, false, getRoutePageName(this.$route), false);
    },
  },
  components: {
    CtaBlockComparePlans,
    SearchVillagesResultsEmpty,
    SearchVillagesResults,
    SearchVillagesFormContainer,
    mcrWikiGlossaryLink,
    McrButton,
    McrButtonRouterLink,
    StandOutBlockSplit,
    SearchError,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/style/search-tool-list.scss';

.village-location {
  border-bottom: 1px solid $divider-line-light;
  margin-top: 24px;
  padding-bottom: 24px;
}

.section {
  margin-top: 50px;
}
</style>
